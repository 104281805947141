import {schoolToLoad} from "./environment.common"

export const environment = {
  production: true,
  UrlAPI: "https://test-user-dot-cedoc-360c.ue.r.appspot.com/api/",
  Url: "https://test-user-dot-cedoc-360c.ue.r.appspot.com/",
  URL_BACK_V2: "https://test-default-dot-cedoc-360c.ue.r.appspot.com/api",

  dialogflow: {
    saraBot: "1e9f879b83eb40bc9a4be925f9274604",
  },
  APP_NAME: "cedoc",
  SHOW_FOOTER: true,

  ...schoolToLoad(),
}
